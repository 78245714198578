<template>
  <div class="page-container">
    <header-tab activeIndex="3-4"></header-tab>
    <div
      class="p-t-header"
      v-lazy:background-image="{src: require('../../assets/images/lunbo2.png')}"
    >
      <div class="p-t-inner">
        <div align="center">
          <span class="home-carousel-s1">首营电子资料交换平台</span>
          <span class="home-carousel-line"></span>
          <span class="home-carousel-s2">数字化信息流转，让管理更高效</span>
        </div>
      </div>
    </div>
    <pain-points></pain-points>
    <platform-advantage></platform-advantage>
    <government-approval></government-approval>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
export default {
  name: "electronicData",
  components: {
    "header-tab": () => import("@/components/headerTab"),
    "pain-points": () => import("./components/PainPoints"),
    "platform-advantage": () => import("./components/PlatformAdvantage"),
    "government-approval": () => import("./components/GovernmentApproval"),
    "nav-footer": () => import("@/components/navFooter"),
  },
};
</script>

<style lang="less" scoped>
.p-t-header {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.p-t-inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>